
import { ApiMethods, HttpMethods } from '@/utility/base';
import { crudQueryNew } from '@/utility/service';
import { session } from '@/utility/auth/session';
import { StatusCodes } from 'http-status-codes';

export interface IAuthResult {
    token: string;
    username: string;
    remember: boolean;
}

interface ISendMailParams {
    username: string;
    email: string;
    name: string;
    pass: string;
    remember: boolean;
}

interface ISendMailResult {
    token: string;
}

interface ICheckUserDataParams {
    username: string;
    email: string;
}

interface ICheckUserDataResult {
    usernameExist: boolean;
    emailExist: boolean;
}

interface IRegisterParams {
    code: string;
    codeToken: string;
}

export class Auth {

    auth(data: IUserData): Promise<IResponseNew<IAuthResult>> {
        return this._call<IAuthResult>(data, HttpMethods.Read, 'auth').then(saveSession);
    }

    checkUserData(data: ICheckUserDataParams): Promise<IResponseNew<ICheckUserDataResult>> {
        return this._call<ICheckUserDataResult>(data, HttpMethods.Read, 'checkUserData');
    }

    sendMail(data: ISendMailParams): Promise<IResponseNew<ISendMailResult>> {
        return this._call<ISendMailResult>(data, HttpMethods.Read, 'sendMail');
    }

    register(data: IRegisterParams): Promise<IResponseNew<IAuthResult>> {
        return this._call<IAuthResult>(data, HttpMethods.Read, 'register').then(saveSession);
    }

    protected _call<TRes>(data: object, httpMethod: HttpMethods, method: string): Promise<IResponseNew<TRes>> {
        const patchedData = {...data, method};
        return crudQueryNew<TRes>(patchedData, httpMethod, ApiMethods.Auth, 'service');
    }
}

function saveSession(res: IResponseNew<IAuthResult>): IResponseNew<IAuthResult> {
    const success = res.code === StatusCodes.OK && !!res.result?.token;

     if (success) {
         session.add({ token: res.result.token, remember: res.result.remember, username: res.result.username });
     }
     return res;
}
