import { dayOfYear } from '../base/time';
import { loadScript } from '../loader';
import { initServiceWorker } from './serviceWorkerInit';

export function initApplication() {
    initServiceWorker();
    const day = dayOfYear();
    if (day > 350 || day < 15) {
        window.garlandConfig = {
            top: '30px',
            zIndex: '0'
        };
        loadScript('https://gagikpog.ru/data/libs/snow.js');
        loadScript('https://gagikpog.ru/data/libs/garland.js');
    }
}
