import { StatusCodes } from 'http-status-codes';

declare global {

    type IEventBusCallback = (...args: any[]) => void;
    type TThemeName = 'dark' | 'light';

    interface Window {
        setNewApi: (value: boolean) => void;
        setDebugApi?: (value: boolean) => void;
        activateGlobalState?: () => void;
        garlandConfig: {
            speed?: number;
            top?: string;
            zIndex?: string;
            width?: string;
            parentNode?: string;
        };
    }

    interface IUserData {
        username: string;
        password: string;
        remember: boolean;
    }

    interface IResponse {
        data?: any;
        message?: string;
        error?: boolean;
    }

    interface IResponseError {

    }

    interface IResponseNew<T> {
        result: T;
        code: StatusCodes;
        status: string;
        message: string;
        errors: IResponseError[];
    }

    interface IAny {
        [key: string]: any;
    }

    type WithProperty<K extends string, V = {}> = {
        [P in K]: string;
    };

    interface IFilm {
        id: string;
        name: string;
        lastView?: string;
        comment?: string;
        createDate: string;
        filmId: string;
        posterUrl?: string;
        nameRu?: string;
        posterUrlPreview?: string;
        myRating?: string;
        type?: FilmTypes;
        meta?: string;
        imdbId?: string;
    }

    interface IFilmMeta {
        dominantColor: string;
        tvShowWatched?: boolean;
    }

    interface IExternalFilm {
        data: IExternalFilmData;
        externalId: {imdbId: string};
    }

    interface IExternalFilmData {
        filmId: number;
        nameRu: string;
        nameEn: string;
        webUrl: string;
        posterUrl: string;
        posterUrlPreview: string;
        year: string;
        filmLength: string;
        slogan: string;
        description: string;
        type: FilmTypes;
        ratingMpaa?: number;
        ratingAgeLimits: number;
        premiereRu: string;
        distributors?: string;
        premiereWorld: string;
        premiereDigital: string;
        premiereWorldCountry: string;
        premiereDvd?: string;
        premiereBluRay?: string;
        distributorRelease?: string;
        countries: { country: string }[];
        genres: { genre: string }[];
        facts: string[];
        seasons: ISeason[];
    }

    interface ISeason {
        number: number;
        episodes: IEpisode[];
    }

    interface IEpisode {
        seasonNumber: number;
        episodeNumber: number;
        nameRu: string;
        nameEn: string;
        synopsis: string;
        releaseDate: string;
    }

    interface IView {
        viewId: string;
        film: string;
        viewDate: string;
        user?: string;
        season?: string;
        episode?: string;
    }

    interface IConfirmationResult {
        button: MsgButtons;
        formData: any;
    }

    interface IConfirmationConfig {
        buttons: {
            id: MsgButtons;
            backgroundColor?: string;
            style: string;
            color?: string;
            order: number;
            title: string;
        }[];
    }

    interface IConfirmationOpenCfg {
        message: string;
        detail: string;
        config: IConfirmationConfig;
    }

    interface ICounter {
        [key: string]: number;
    }

    interface IFilterGroupItem {
        id: string;
        title: string;
        items: IFilterItem[];
        resetValue: string | null;
        multiSelect?: boolean;
        emptyTitle?: string;
    }

    interface IFilterItem {
        id: string;
        title: string;
    }

    type IDebounceResult = (...args: any) => void;

    interface ICrudResult<T> {
        result: T;
        error: boolean;
    }

    interface IUser {
        login: string;
        name: string;
        email: string;
        sex: Sex;
        image: string;
    }

    interface IPopupOptions {
        target?: HTMLElement;
    }

    interface IPopup extends Omit<HTMLDialogElement, 'show' | 'showModal'> {
        show(opt?: IPopupOptions): void;
        showModal(opt?: IPopupOptions): void;
    }

}

export enum FilmTypes {
    Film = 'FILM',
    TVShow = 'TV_SHOW',
    TVSeries = 'TV_SERIES'
}

export enum ButtonType {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset'
}

export enum ButtonStyle {
    Transparent = 'transparent',
    Disabled = 'disabled',
    Success = 'success',
    Default = 'default',
    Primary = 'primary'
}

export enum MsgButtons {
    OK = 'MB_OK',
    Yes = 'MB_YES',
    Cancel = 'MB_CANCEL',
    No = 'MB_NO'
}

export enum ApiMethods {
    FilmsPublicPath = 'publicFilms',
    FilmsInfoPath = 'readFilmInfo',
    FilmsSuggestPath = 'filmSuggest',
    ViewsListPath = 'viewList',
    Service = 'index',
    Auth = 'auth'
}

export enum HttpMethods {
    Create = 'POST',
    Read = 'PATCH',
    Update = 'PUT',
    Delete = 'DELETE'
}

export enum Sex {
    Male = 'm',
    Female = 'f'
}

export const UnknownDate = '2000-01-01 00:00:00';
